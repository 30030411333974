$(document).ready(() => {
  // Offset Fixed Navigation
  $('.nav-link, .mobile-links a, .dropdown-item, .btn-prime.btn-prime-dark').click((e) => {
    const linkTarget = $(e.currentTarget).attr('href');

    if (linkTarget == null || linkTarget === '#' || linkTarget.charAt(0) !== '#') {
      return;
    }
    e.preventDefault();

    let topOffset = $(linkTarget).offset().top;
    let navContainer = '.fixed-top';
    if ($('.mobile-nav').is(':visible')) {
      if ($('#burger').is(':checked')) {
        navContainer = '.mobile-menu input~nav';
        $('#burger').trigger('click');
      }
    }

    topOffset -= $(navContainer).height();

    $('html, body').animate({
      scrollTop: topOffset,
    }, 900);
  });

  // $('document').on('change', '#mce-responses', () => {
  //   $('.input-area').hide(1000);
  // });

  // $('#mc-embedded-subscribe').on('mousedown', () => {
  //   console.log();
  //   if ($('#mce-success-response').html().length > 0) {
  //     $('.input-area').hide(1000);
  //     $('#mce-success-response').css('margin', '0');
  //   }
  // });


  // Slick Slider arrows
  $('.arrow-left').click(() => {
    $('.quotes').slick('slickPrev');
  });

  $('.arrow-right').click(() => {
    $('.quotes').slick('slickNext');
  });

  // Wheel Click Modal
  $('button.btn-prime, button').on('mousedown', (e) => {
    if (e.which === 2) {
      e.preventDefault();
      e.stopPropagation();
      $(e.currentTarget).trigger('click');
    }
  });
});